import { z } from 'zod';

export const salesAgentSchema = z.object({
  id: z.string().nonempty(),
  // agent_id: z.number(),

  // 会社名
  name: z.string().nonempty(),

  // 電話番号
  phoneNumber: z.string(),

  // FAX番号
  faxNumber: z.string(),

  // 郵便番号
  postalCode: z.string(),

  // 住所
  address1: z.string(),
  address2: z.string(),

  // 手入力発注フォーム
  manualOrderFormUrl: z.string(),

  // is_active: z.boolean(),
  // created_at: z.string().datetime().nonempty(),
  // updated_at: z.string().datetime().nonempty(),
});

export type SalesAgent = z.infer<typeof salesAgentSchema>;
